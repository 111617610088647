@tailwind base;
@tailwind components;
@tailwind utilities;

::-webkit-scrollbar {
  width: 0px;
}

@keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.custom-gradient {
  background: linear-gradient(270deg, #008480, #00a991, #008480);
  background-size: 400% 400%;
  animation: gradient-animation 6s ease infinite;
}

.audiowide-regular {
  font-family: "Audiowide", sans-serif;
  font-weight: 500;
  font-style: normal;
}
